<template>
  <div class="intro">
      <div>
        <div class="step-title">iOS 安装</div>
        <div class="step-box">
          <div class="step-number">1</div>
          <div>安装苹果官方<br/>&nbsp;&nbsp;测试软件 TestFlight</div>
          <br>
          <div class='app-store-like'>
            <a href="https://apps.apple.com/cn/app/id899247664">
          <span>
            <img class="app-icon" src="@/assets/images/icon_testflight.png"/>
          </span>
              <span>
            <ul>
              <li class="app-title">TestFlight</li>
              <li class="app-intro">将跳转至 AppStore</li>
              <li class="app-get">安装</li>
            </ul>
          </span>
            </a>
          </div>
        </div>
        <div class="step-box">
          <div class="step-number">2</div>
          <div>&nbsp;&nbsp;完成 ➊ 后，安装狗急加速</div>
          <br>
          <div @click="downloadInstall(['download-button-ios-testflight'], $event)" class="download-button download-button-ios-testflight app-store-like">
          <span>
            <img class="app-icon" src="@/assets/images/icon_app.png"/>
          </span>
            <span>
            <ul>
              <li class="app-title">狗急加速</li>
              <li class="app-intro">将跳转至 TestFlight</li>
              <li class="app-get">安装</li>
            </ul>
          </span>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
  import { useStore } from 'vuex'
  export default {
    name: 'IOSFestflight',
    components: {},
    setup () {
      let stores = useStore()
      function downloadInstall (className, event) {
        stores.commit('changeToPath', {tag:'toIosTestflightFaq', value: true})
        let resp = window.install('', className)
        if (!resp) event.preventDefault()
        return resp
      }
      return {
        downloadInstall
      }
    }
  }
</script>

<style scoped>

</style>
